html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  background: rgba(22, 24, 24, 1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
}

#root {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  font-family: 'Titillium Web', sans-serif;
}